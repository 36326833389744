import { InputAdornment, makeStyles, TextField } from "@material-ui/core";
import classNames from "classnames";
import { getAsyncRequest, useCaptureEventsV2, useUserState } from "gx-npm-lib";
import { IconButton, TooltipV2 } from "gx-npm-ui";
import { ChangeEvent, KeyboardEvent, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClientEvent } from "../../../../../app.constants";
import { AppContext } from "../../../../../app.context";
import { TemplateHubSearchApiResponse } from "../../../../../app.types";
import MagnifyGlassIcon from "../../../../../assets/icons/magnify-glass.icon";
import { reportFullStorySearchEvent, storeFullStorySearchBrowseEvent } from "../../../../../lib/full-story-events.lib";
import ClearSearchButtonWithTooltip from "./clear-search-button/clear-search-button-with-tooltip.component";
import styles from "./template-search-input.styles";

const MIN_SEARCH_CHAR_COUNT = 2;
const SEARCH_API_DEBOUNCE_TIME = 550;

const useStyle = makeStyles(() => styles);
const TemplateSearchInput = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const inputRef = useRef<HTMLElement>();
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isSearchDisabled, setIsSearchDisabled] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const { hashedUserId, isGartnerAssociate, isEntitled } = useUserState();
  const captureEvents = useCaptureEventsV2();
  const {
    templateData,
    setCategoryId,
    setCategoryName,
    setIsError,
    setIsSearchData,
    setIsSearching,
    setSearchTerm,
    setSelectedCategoryTemplates,
  } = useContext(AppContext);

  const onClearSearch = () => {
    switchToCategory();
    setInputValue("");
    setSearchTerm("");
    setIsSearchDisabled(true);
    inputRef?.current?.focus();
  };

  const switchToSearch = useCallback(() => {
    if (isSearchDisabled) {
      return;
    }
    setIsSearching(true);
    setIsSearchData(true);
    setSearchTerm(inputValue);
    setCategoryName("");
    setSelectedCategoryTemplates([]);
  }, [
    inputValue,
    isSearchDisabled,
    setCategoryName,
    setIsSearchData,
    setIsSearching,
    setSearchTerm,
    setSelectedCategoryTemplates,
  ]);

  const switchToCategory = useCallback(() => {
    setCategoryId(0);
    setCategoryName("");
    setSelectedCategoryTemplates(templateData);
    setIsSearchData(false);
    setIsSearching(false);
  }, [setCategoryId, setCategoryName, setIsSearchData, setIsSearching, setSelectedCategoryTemplates, templateData]);

  const onSearch = useCallback(async () => {
    if (isSearchDisabled) {
      return;
    }
    const config = { hashedUserId, isEntitled, searchTerm: inputValue, includeMocks: isGartnerAssociate };
    const qParams = [`q=${encodeURIComponent(config.searchTerm)}`];
    if (config.includeMocks) {
      qParams.push(`includeMocks=${config.includeMocks}`);
    }
    const url = `/api/v3/data/template/search?${qParams.join("&")}`;
    const response: TemplateHubSearchApiResponse = await getAsyncRequest(url);
    if (response?.status === 200 && Array.isArray(response.data?.data)) {
      const resultsCount = response.data.data.length;
      reportFullStorySearchEvent({ ...config, resultsCount });
      storeFullStorySearchBrowseEvent({
        categoryId: 0,
        categoryName: "",
        isSearchData: true,
        searchResultsCount: resultsCount,
        searchTerm: config.searchTerm,
      });

      if (resultsCount > -1) {
        const metaData = { isEntitled, resultsCount, searchTerm: config.searchTerm.toLocaleLowerCase() };
        captureEvents([{ eventType: ClientEvent.MARKETS_SEARCH_BY_KEYWORD, metaData }]);
      }
      setSelectedCategoryTemplates(response.data.data);
    } else {
      setIsError(true);
    }
    setIsSearching(false);
  }, [
    captureEvents,
    hashedUserId,
    inputValue,
    isEntitled,
    isGartnerAssociate,
    isSearchDisabled,
    setIsError,
    setIsSearching,
    setSelectedCategoryTemplates,
  ]);

  const handleChange = (event: ChangeEvent) => {
    const { value } = event.target as HTMLInputElement;
    if (!value) {
      onClearSearch();
    }
    setInputValue(value);
    setIsSearchDisabled(value.trim().length < 1 || value.trim().length > 240);
  };

  const handleKeyDown = async (event: KeyboardEvent) => {
    if (isFocused && event.key === "Escape") {
      onClearSearch();
    } else if (isFocused && event.key === "Enter") {
      switchToSearch();
      await onSearch();
    }
  };

  useEffect(() => {
    if (inputValue.length < MIN_SEARCH_CHAR_COUNT) {
      switchToCategory();
    } else {
      switchToSearch();
      const searchTimeOut = setTimeout(onSearch, SEARCH_API_DEBOUNCE_TIME);
      return () => clearTimeout(searchTimeOut);
    }
  }, [inputValue, onSearch, switchToCategory, switchToSearch, templateData]);

  return (
    <div className={classNames(classes.container, "gx-search-input-container")}>
      <div
        className={classNames(
          classes.inputContainer,
          isHovered && "hovered",
          isFocused && "focused",
          !!inputValue && "populated"
        )}
      >
        <TextField
          className={classNames(classes.inputField, !!inputValue && "has-search-term")}
          fullWidth={true}
          inputProps={{ maxLength: 240 }}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                {!!inputValue && <ClearSearchButtonWithTooltip onClearSearch={onClearSearch} />}
              </InputAdornment>
            ),
          }}
          inputRef={inputRef}
          onBlur={() => setIsFocused(false)}
          onFocus={() => setIsFocused(true)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          placeholder={t("Search by keyword or vendor")}
          type="text"
          value={inputValue}
        />
      </div>
      <TooltipV2
        deactivate={isSearchDisabled}
        enterDelay={400}
        enterNextDelay={400}
        placement="top"
        PopperProps={{ modifiers: { offset: { offset: "0, 2px" } } }}
        title={t("Search")}
      >
        <div className={classNames(classes.searchButton, !isSearchDisabled && "enabled")}>
          <IconButton ariaLabel={t("search")} disabled={isSearchDisabled} onClick={onSearch}>
            <MagnifyGlassIcon disabled={isSearchDisabled} />
          </IconButton>
        </div>
      </TooltipV2>
    </div>
  );
};
export default TemplateSearchInput;
